import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Layout, Menu, Avatar, Button, Divider } from 'antd';
import {
  MenuFoldOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { AclContext } from 'src/modules/acl';
import { ConfigContext } from 'src/modules/config';
import { AuthenticationContext } from 'src/modules/authentication';
import siteconfig from 'src/siteconfig';
import './mainLayout.less';
import { onCreateMenu, onCreateMenuHeader } from './MenuLayout';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

function MainLayout({ children }) {
  const [collapsed, toggle] = useState(false);
  const acl = useContext(AclContext);
  const auth = useContext(AuthenticationContext);
  const config = useContext(ConfigContext);
  const history = useHistory();
  const { activePlatforms } = acl.state;
  const { profile } = auth.state;

  useEffect(() => {
    toggle(config.state.isMobileSite);
  }, [config.state.isMobileSite]);

  if (!activePlatforms) return null;
  let activeMenu = Array.from(new Set(activePlatforms.menu));

  let menuDefault = siteconfig[`${activePlatforms.merchant_id}`].menus;

  let activeMenuIndex = activeMenu
    .findIndex((d) => history.location.pathname.includes(d))
    .toString(); // select first menu by default
  let selectedIndex = menuDefault.findIndex((menu) => menu.key === activeMenu[activeMenuIndex]); // siteconfig index >= activeMenu index
  let pageTitle =
    siteconfig[`${config.state.appName}`].pageTitle ||
    siteconfig[`${config.state.appName}`].pageTitle === '' // can be empty string
      ? siteconfig[`${config.state.appName}`].pageTitle
      : 'Dashboard';
  let headerMenu = siteconfig[`${config.state.appName}`].headerMenu || [];

  function onClickMenu(menuKey) {
    if (config.state.isMobileSite) toggle(true);
    history.push(`/list/${menuKey}`);
  }
  const isEligible = acl.action.checkEligibility('SIDEBAR.');

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={config.state.isMobileSite ? '100%' : config.state.appName === 'prospeku' ? 260 : 260}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={
          config.state.appName === 'exploration' && {
            backgroundColor: '#313131',
          }
        }
      >
        <div className={`logo logo-${config.state.appName}`}>
          <div>
            {config.state.isMobileSite && (
              <div style={{ position: 'absolute', left: 20, top: 20 }}>
                <Button type="primary" onClick={() => toggle(!collapsed)} icon={<MenuOutlined />} />
                {/* "menu-unfold" */}
              </div>
            )}
            <div
              style={{
                fontSize: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                style={{ width: '200px', height: '50px', objectFit: 'cover' }}
                src={`/images/${config.state.appName}.png`}
              />
              {/* <p className="title-sidebar">{pageTitle}</p> */}
            </div>
          </div>
        </div>
        {onCreateMenu({ selectedIndex, config, activeMenu, isEligible, onClickMenu, siteconfig })}
      </Sider>
      <Layout>
        <Header>
          <div className="header-navbar-shadow" />
          <Row align="middle" justify="space-between">
            <button className="trigger" onClick={() => toggle(!collapsed)}>
              {/* {!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} */}
              <MenuOutlined /> {/* eliving */}
            </button>
            <Row align="middle">
              {headerMenu.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
              {onCreateMenuHeader(profile, activePlatforms)}
            </Row>
          </Row>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;

import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './pageNotFound.less';

const PageNotFound = () => (
  <div className="page-404">
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className=" ">
            <div className="">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>

                <p>the page you are looking for not avaible!</p>

                <Button icon={<ArrowLeftOutlined />} type="primary" href="/" size="large">
                  Go to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default PageNotFound;

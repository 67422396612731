export interface IAcl {
  merchant_id: string;
  role: string;
  roles: string[];
  menu: string[];
}

export interface IState {
  aclList: IAcl[];
  platforms: string[] | null;
  activePlatforms: IAcl;
}

export enum IActionTypes {
  SET_STATE = 'SET_STATE',
  SET_LOADING = 'SET_LOADING',
}

export interface IAction {
  getMenus: (userId: string, phone: string) => void;
  setEligibility: (eligible: any) => void;
  checkEligibility: Function;
}

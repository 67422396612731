import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Form, Card, Input, Row, Button, Typography } from 'antd';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { AuthenticationContext, IAuthTypes } from 'src/modules/authentication';
import { AclContext } from 'src/modules/acl';
import { ConfigContext } from 'src/modules/config';
import siteconfig from 'src/siteconfig';
import { validators, metaError } from 'src/validations';
import { notifyError } from 'src/api';
//@ts-ignore
import OtpInput from 'react-otp-input';
import './login.less';
import { IRolesExploration } from '../../interfaces/user/types.d';
import { saveToLocalStorage } from 'src/modules/helpers';

function Login() {
  const [disableButton, setDisableButton] = useState(false);
  const [countDown, setCountDown] = useState(15);
  const authCtx = useContext(AuthenticationContext);
  const configCtx = useContext(ConfigContext);
  const { state, action } = authCtx;
  const acl = useContext(AclContext);
  const history = useHistory();
  const isEligible = IRolesExploration;

  React.useEffect(() => {
    if (countDown === 0) {
      setDisableButton(false);
      setCountDown(15);
    } else {
      setDisableButton(countDown !== 15);
    }
    if (countDown > 0 && countDown <= 14) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown]);

  async function onSubmit(formData: {
    phone: string;
    password: string;
    confirmPassword: string;
    otp: string;
  }) {
    let errors: any = {};
    if (state.renderView === IAuthTypes.IRenderView.ENTER_PHONE_NUMBER) {
      // action.validatePhoneNumber({ phone: formData.phone });
      action.setEmailValue(formData.phone);
      action.switchRenderView(IAuthTypes.IRenderView.LOGIN_PASSWORD);
      return;
    }

    if (state.renderView === IAuthTypes.IRenderView.LOGIN_PASSWORD) {
      try {
        const profile: any = await action.login(formData);
        if (profile !== 403) {
          action.setIsAuth(true);
          const checkRoles = profile.scopes.find((item) => item == 'all');
          const role = !!checkRoles ? 'SUPER_ADMIN' : 'STAFF_ADMIN';
          const aclState = {
            aclList: [
              {
                merchant_id: 'exploration',
                role: '',
                roles: [role],
                menu: ['admission', 'invoice', 'university'],
              },
            ],
            platforms: 'exploration',
            activePlatforms: {
              merchant_id: 'exploration',
              role: '',
              roles: [role],
              menu: ['admission', 'invoice', 'university'],
            },
            eligibility: null,
            eligible:
              isEligible.SUPER_ADMIN === role ? isEligible.SUPER_ADMIN : isEligible.STAFF_ADMIN,
          };

          saveToLocalStorage('acl', aclState);
          window.location.reload();
        } else {
          errors.password =
            configCtx.state.appName === 'oneinvoice'
              ? `Confirmed your email ${formData.phone}`
              : `Verifikasi email anda ${formData.phone}`;
        }
      } catch (error) {
        errors.password =
          configCtx.state.appName === 'oneinvoice'
            ? 'The data that you entered is invalid'
            : 'Data yang anda masukkan salah';
      }
      return errors;
    }

    if (state.renderView === IAuthTypes.IRenderView.CREATE_PASSWORD) {
      action.forgotPassword(formData.confirmPassword);
      return;
    }
    if (state.renderView === IAuthTypes.IRenderView.VERIFY_OTP) {
      // console.log(formData);
      // action.forgotPassword(formData.confirmPassword);
      return;
    }
  }

  function onValidate(values) {
    let errors: any = {};
    if (state.renderView === IAuthTypes.IRenderView.CREATE_PASSWORD) {
      if (!values.newPassword) {
        errors.newPassword =
          configCtx.state.appName === 'oneinvoice'
            ? 'New password must be filled'
            : 'Password baru harus di isi';
      }
      if (!values.confirmPassword) {
        errors.confirmPassword =
          configCtx.state.appName === 'oneinvoice'
            ? 'New password confirmation must be filled'
            : 'Konfirmasi Password baru harus di isi';
      }
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = values.phone
          ? configCtx.state.appName === 'oneinvoice'
            ? 'Password not matched'
            : 'Password tidak sesuai'
          : configCtx.state.appName === 'oneinvoice'
          ? 'Password confirmation must be same with the new one'
          : 'Konfirmasi password harus sama dengan password yang baru';
      }
      if (values.newPassword) {
        const passRegex = /^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i;
        if (!passRegex.test(values.newPassword) || values.newPassword.length < 6) {
          errors.newPassword =
            configCtx.state.appName === 'oneinvoice'
              ? 'Password format is invalid'
              : 'Format penulisan belum sesuai';
        }
      }
    }

    if (state.renderView === IAuthTypes.IRenderView.ENTER_PHONE_NUMBER) {
      if (typeof values.phone !== 'undefined') {
        if (/^\d+$/.test(values.phone)) {
          //as number
          if (
            values.phone.length < 9 ||
            values.phone.length > 13 ||
            isNaN(values.phone) ||
            (values.phone.charAt(0) !== '0' && values.phone.substring(0, 2) !== '62')
          ) {
            errors.phone =
              configCtx.state.appName === 'oneinvoice'
                ? 'Email / number format is invalid'
                : 'Format nomor / email  yang Anda masukkan tidak sesuai';
          }
        } else {
          //as email
          if (/\S+@\S+\.\S+/.test(values.phone) === false) {
            errors.phone =
              configCtx.state.appName === 'oneinvoice'
                ? 'Email / number format is invalid'
                : 'Format email / nomor yang Anda masukkan tidak sesuai';
          }
        }
      } else {
        errors.phone =
          configCtx.state.appName === 'oneinvoice'
            ? 'Number format is invalid, please try again'
            : 'Format nomor salah, silakan ulangi lagi';
      }
    }

    if (state.renderView === IAuthTypes.IRenderView.LOGIN_PASSWORD) {
      if (!values.password) {
        errors.password =
          configCtx.state.appName === 'oneinvoice'
            ? 'Password must be filled'
            : 'Password harus diisi';
      }
    }
    return errors;
  }

  function onForgotPassword(values) {
    action.sendOtp(values.phone);
    action.switchRenderView(IAuthTypes.IRenderView.VERIFY_OTP);
  }

  function onChangeOtp(otp, input, phone) {
    input.onChange(otp);
    if (otp.length == 6) {
      action
        .validateOtp(phone, otp)
        .then(() => {})
        .catch((err: any) => notifyError(err));
    }
  }

  function onResendOtp(phone) {
    action.sendOtp(phone);
    action.switchRenderView(IAuthTypes.IRenderView.VERIFY_OTP);
    setCountDown(countDown - 1);
  }

  if (siteconfig[configCtx.state.appName].login) {
    return React.cloneElement(siteconfig[configCtx.state.appName].login, {
      onSubmit,
      onForgotPassword,
      onValidate,
      onChangeOtp,
      onResendOtp,
      loginState: state,
      disableResendButton: disableButton,
      switchRenderView: action.switchRenderView,
      countDown,
    });
  }

  return (
    <div className="login-page">
      <Card className="login-card">
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img
            style={{ marginBottom: '30px', marginTop: '24px' }}
            width="300"
            src={`/images/${configCtx.state.appName}_blk.png`}
            alt={configCtx.state.appName}
          />
          <Typography.Title level={3}>Portal Partner Universitas</Typography.Title>
          <Typography.Text>Administrator Only</Typography.Text>
        </div>
        <FinalForm
          initialValues={{ phone: '', password: '', confirmPassword: '', otp: '' }}
          onSubmit={onSubmit}
          validate={onValidate}
          render={({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {state.renderView === IAuthTypes.IRenderView.ENTER_PHONE_NUMBER && (
                <Field validate={validators('email')} name="phone">
                  {({ input, meta }) => (
                    <Form.Item
                      label={'Masukkan Email PIC'}
                      help={metaError(meta) && <span>{meta.error}</span>}
                      validateStatus={metaError(meta) ? 'error' : ''}
                    >
                      <Input {...input} size="large" placeholder={'Masukkan Masukkan Email PIC'} />
                    </Form.Item>
                  )}
                </Field>
              )}
              {state.renderView === IAuthTypes.IRenderView.CREATE_PASSWORD && (
                <div>
                  <Field name="newPassword">
                    {({ input, meta }) => (
                      <Form.Item
                        label={
                          configCtx.state.appName === 'oneinvoice'
                            ? 'Input New Password'
                            : 'Masukkan Password Baru'
                        }
                        help={metaError(meta) && <span>{meta.error}</span>}
                        validateStatus={metaError(meta) ? 'error' : ''}
                      >
                        <Input.Password
                          {...input}
                          size="large"
                          placeholder={
                            configCtx.state.appName === 'oneinvoice'
                              ? 'Input New Password'
                              : 'Masukkan Password Baru'
                          }
                          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ input, meta }) => (
                      <Form.Item
                        label={
                          configCtx.state.appName === 'oneinvoice'
                            ? 'Repeat New Password'
                            : 'Ulangi Password Baru'
                        }
                        help={metaError(meta) && <span>{meta.error}</span>}
                        validateStatus={metaError(meta) ? 'error' : ''}
                      >
                        <Input.Password
                          {...input}
                          size="large"
                          placeholder={
                            configCtx.state.appName === 'oneinvoice'
                              ? 'Repeat New Password'
                              : 'Ulangi Password Baru'
                          }
                          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        />
                      </Form.Item>
                    )}
                  </Field>
                </div>
              )}

              {state.renderView === IAuthTypes.IRenderView.LOGIN_PASSWORD && (
                <div>
                  <Field validate={validators('required')} name="password">
                    {({ input, meta }) => (
                      <>
                        <Form.Item
                          label={
                            configCtx.state.appName === 'oneinvoice'
                              ? 'Input password to login'
                              : 'Masukkan password untuk login'
                          }
                          validateStatus={
                            metaError(meta) || (meta.submitError && meta.touched) ? 'error' : ''
                          }
                        >
                          <Input
                            {...input}
                            size="large"
                            type="password"
                            placeholder={
                              configCtx.state.appName === 'oneinvoice'
                                ? 'Input password to login'
                                : 'Masukkan password untuk login'
                            }
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                          />
                          {(meta.error || meta.submitError) && meta.touched && (
                            <div className="ant-form-item-explain">
                              <span>{meta.error || meta.submitError}</span>
                            </div>
                          )}
                        </Form.Item>
                        <Button onClick={() => onForgotPassword(values)} type="link">
                          Forgot Password?
                        </Button>
                      </>
                    )}
                  </Field>
                </div>
              )}

              {state.renderView === IAuthTypes.IRenderView.VERIFY_OTP && (
                <div>
                  <div style={{ marginBottom: '15px' }}>
                    <Typography.Text>
                      {configCtx.state.appName === 'oneinvoice'
                        ? `Input the verification code that has been sent to your phone number (${values.phone}). This code is valid for five minutes`
                        : `Masukkan kode verifikasi yang baru saja kami kirimkan ke email Anda
                      (${values.phone}). Kode ini valid untuk 5 menit`}
                    </Typography.Text>
                  </div>
                  <Field name="otp">
                    {({ input, meta }) => (
                      <Form.Item
                        help={metaError(meta) && <span>{meta.error}</span>}
                        validateStatus={metaError(meta) ? 'error' : ''}
                      >
                        <OtpInput
                          onChange={(otp: any) => onChangeOtp(otp, input, values.phone)}
                          containerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                          numInputs={6}
                          value={values.otp}
                          separator={<span style={{ padding: '7px' }}>-</span>}
                          inputStyle={{ width: '35px' }}
                        />
                        {!disableButton && (
                          <Button
                            onClick={() => onResendOtp(values.phone)}
                            type="link"
                            disabled={disableButton}
                          >
                            {configCtx.state.appName === 'oneinvoice'
                              ? 'Resend ?'
                              : 'Kirim ulang ?'}
                          </Button>
                        )}
                        {disableButton && (
                          <div style={{ alignItems: 'center' }}>
                            <Typography.Text>
                              {configCtx.state.appName === 'oneinvoice'
                                ? 'Resend in: 00:'
                                : 'Kirim ulang dalam: 00:'}
                              {`${countDown < 10 ? '0' : ''}${countDown}`}
                            </Typography.Text>
                          </div>
                        )}
                      </Form.Item>
                    )}
                  </Field>
                </div>
              )}

              {state.renderView !== IAuthTypes.IRenderView.VERIFY_OTP && (
                <div style={{ margin: '20px 0px' }}>
                  <Button
                    loading={state.loading === IAuthTypes.ILoading.LOGIN_FORM}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ width: '100%' }}
                  >
                    {state.renderView === IAuthTypes.IRenderView.ENTER_PHONE_NUMBER && 'Next'}
                    {state.renderView === IAuthTypes.IRenderView.LOGIN_PASSWORD && 'Login'}
                    {/* state.renderView === IAuthTypes.IRenderView.VERIFY_OTP && 'Kirim Ulang ?' */}
                    {state.renderView === IAuthTypes.IRenderView.CREATE_PASSWORD && 'Next'}
                  </Button>
                </div>
              )}
              {state.renderView === IAuthTypes.IRenderView.LOGIN_PASSWORD && (
                <Button
                  onClick={() => action.switchRenderView(IAuthTypes.IRenderView.ENTER_PHONE_NUMBER)}
                  type="link"
                  style={{ width: '100%' }}
                >
                  Back
                </Button>
              )}
            </form>
          )}
        />
        <br />

      </Card>
    </div>
  );
}

export default Login;

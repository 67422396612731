import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { DownOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

export function onCreateMenuHeader(profile, activePlatforms) {
  let name = profile.name;

  if (window.location.hostname.includes('workfit') && name.length > 13) {
    name = name.substring(0, 12) + '...';
  }
  return (
    <Menu
      mode="horizontal"
      className="menu-header"
      style={{ minWidth: activePlatforms.merchant_id === 'supplychain' ? '300px' : '200px' }}
    >
      <Menu.SubMenu
        key="submenu1"
        className="submenu-header"
        title={
          <span className="submenu-title-wrapper profile-name">
            <Avatar style={{ marginRight: '10px' }}>{profile.name.slice(0, 1)}</Avatar>
            {name}
            {activePlatforms.merchant_id === 'supplychain' && (
              <span className="role">{activePlatforms.roles[0]}</span>
            )}
            <DownOutlined style={{ paddingLeft: '10px' }} />
          </span>
        }
      >
        {/* {!window.location.hostname.includes('workfit') && (
          <Menu.Item key="setting:1" icon={<SettingOutlined />}>
            <Link to="/setting/profile">settings</Link>
          </Menu.Item>
        )} */}
        <Menu.Item key="setting:2" icon={<LogoutOutlined />}>
          <Link to="/logout">Logout</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

export function onCreateSubMenu(menu, onClickMenu, key, isEligible, activeMenu, overrideMenus) {
  if (!isEligible(menu.key)) {
    return null;
  }

  return (
    <Menu.SubMenu key={`${menu.key}-${key}`} icon={menu.icon || undefined} title={menu.name}>
      {menu.children.map((submenu) =>
        submenu?.children && submenu?.children.length > 0
          ? onCreateSubMenu(
              submenu,
              onClickMenu,
              submenu.key,
              isEligible,
              activeMenu,
              overrideMenus,
            )
          : onCreateMenuTitle(
              submenu,
              onClickMenu,
              menu,
              '',
              isEligible,
              activeMenu,
              overrideMenus,
            ),
      )}
    </Menu.SubMenu>
  );
}

export function onCreateMenuTitle(
  submenu,
  onClickMenu,
  menu,
  key,
  isEligible,
  activeMenu,
  overrideMenus,
) {
  if (!isEligible(submenu.key)) {
    return null;
  }
  if (!activeMenu?.includes(submenu.key) && !overrideMenus) {
    return null;
  }
  return (
    <Menu.Item
      icon={submenu.icon || undefined}
      onClick={() => {
        window.sessionStorage.setItem('menukey', `${menu.key}-${submenu.key}-${key}`);
        onClickMenu(submenu.key);
      }}
      key={`${menu.key}-${submenu.key}-${key}`}
    >
      <span className="text-menu" style={{ textTransform: 'capitalize' }}>
        {submenu.name}
      </span>
    </Menu.Item>
  );
}

export function onCreateMenu({
  selectedIndex,
  config,
  activeMenu,
  isEligible,
  onClickMenu,
  siteconfig,
}) {
  const overrideMenus = siteconfig[`${config.state.appName}`].overrideMenus;
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[window.sessionStorage.getItem('menukey') || selectedIndex.toString()]}
    >
      {siteconfig[`${config.state.appName}`].menus.map((menu, key) => {
        return activeMenu.includes(menu.key) || overrideMenus
          ? !isEligible(menu.key)
            ? null
            : menu.children
            ? onCreateSubMenu(menu, onClickMenu, key, isEligible, activeMenu, overrideMenus)
            : onCreateMenuTitle(menu, onClickMenu, menu, key, isEligible, activeMenu, overrideMenus)
          : null;
      })}
    </Menu>
  );
}

import { useReducer } from 'react';
import { api, to } from 'src/api';
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import { notifyError, getAppNameFromDomain } from 'src/utils';
import { IState, IActionTypes } from './types.d';
import { CLIENT_ID } from '../../constants';
import { PREDEFINED_USERS } from 'src/siteconfig/predefined_users';

function phoneFormatter(phone: string) {
  if (phone.slice(0, 2) === '62') {
    return `0${phone.slice(2)}`;
  }
  return phone;
}

function reducer(
  state: IState,
  action: {
    type: IActionTypes.SET_LOADING | IActionTypes.SET_STATE;
    value?: any;
    key?: string;
  },
) {
  if (action.type === IActionTypes.SET_STATE) {
    return { ...state, ...action.value };
  }
  if (action.type === IActionTypes.SET_LOADING) {
    return { ...state, loading: action.value };
  }
  throw new Error();
}

function useAction(initialState: IState) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getMenus(userId: string, phone?: string) {
    const response = await to(api.base.get(`/user/v2/acl/users/${userId}/menu`));
    const appName = getAppNameFromDomain();
    if (response.err) {
      notifyError(response.err);
      return;
    }
    if (response.res.data.content.length == 0) {
      notifyError('Akses ditolak, Anda bukan Admin');
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }

    let activePlatforms: any = null;
    let platforms: any = null;
    let aclList: any[] = [];

    const merchant = response.res.data.content.find((d) => d.merchant_id === appName);
    platforms = 'exploration';
    activePlatforms = {
      merchant_id: 'exploration',
      role: '',
      roles: ['SUPERADMIN'],
      menu: ['articles'],
    };

    aclList = [
      {
        menu: ['articles'],
        merchant_id: 'exploration',
        role: '',
        roles: ['SUPERADMIN'],
      },
    ];
    dispatch({
      type: IActionTypes.SET_STATE,
      value: { aclList, platforms, activePlatforms },
    });
    Cookies.set(
      `${process.env.REACT_APP_APP_CLIENT_ID}.clientId`,
      CLIENT_ID(window.location.hostname),
    );
  }

  function setEligibility(eligible: any) {
    dispatch({ type: IActionTypes.SET_STATE, value: { eligible } });
  }
  const checkEligibility = (prefix: string = '') => (path: string = '') => {
    return _get(state.eligible, `${prefix}${path}`, true);
  };

  return {
    state,
    action: {
      getMenus,
      setEligibility,
      checkEligibility,
    },
  };
}

export default useAction;

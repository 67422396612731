export enum IRenderView {
  CREATE_PASSWORD = 'CREATE_PASSWORD',
  VERIFY_OTP = 'VERIFY_OTP',
  LOGIN_PASSWORD = 'LOGIN_PASSWORD',
  ENTER_PHONE_NUMBER = 'ENTER_PHONE_NUMBER',
  SUCCESS_LOGIN = 'SUCCESS_LOGIN',
  FAILED_LOGIN = 'FAILED_LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum ILoading {
  LOGIN_FORM = 'LOGIN_FORM',
}

export enum IActionTypes {
  SET_STATE = 'SET_STATE',
  SET_LOADING = 'SET_LOADING',
  SET_PROFILE = 'SET_PROFILE',
}

export enum IUserType {
  ADMIN = 1,
  SUPER_ADMIN = 2,
}

export interface IState {
  loading: ILoading | null;
  profile?: any;
  renderView: string;
  otp_verification_id: string;
  role: IUserType;
  isAuth: boolean;
  email: string;
}

export interface IAction {
  login: ({ phone, password }: { phone: string; password: string }) => void;
  logout: () => void;
  validateOtp: Function;
  forgotPassword: Function;
  sendOtp: Function;
  setIsAuth: (any: boolean) => void;
  switchRenderView: (renderView: string) => void;
  validatePhoneNumber: ({ phone }: { phone: string }) => void;
  updateProfile: (any: any) => void;
  updatePhone: (any: any) => void;
  setEmailValue: (any: any) => void;
}

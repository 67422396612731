import axios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import _isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import store from 'store2';
import { notification } from 'antd';
import _get from 'lodash/get';
import siteconfig from 'src/siteconfig';
import { getAppNameFromDomain } from 'src/utils';
import { CLIENT_ID } from '../constants';

const SITE_CONFIG: any = siteconfig;

interface IUriServices {
  [key: string]: string;
}

interface IUriInstances {
  [key: string]: AxiosInstance;
}

let { REACT_APP_APP_CLIENT_ID, REACT_APP_API_BASE_URL, REACT_APP_APP_VERSION} = process.env;

export const DEFAULT_HEADER_CONFIG = {
  'Content-Type': 'application/json',
  // 'X-Client-Id': REACT_APP_APP_CLIENT_ID,
  // 'X-Device-Id': Cookies.get(`${REACT_APP_APP_CLIENT_ID}.uuid`),
  // 'X-Client-Version': REACT_APP_APP_VERSION || '1.0.0',
  // 'X-Frame-Options': 'SAMEORIGIN',
};

export const to = (promise: AxiosPromise) =>
  promise
    .then((data: AxiosResponse) => ({ err: null, res: data }))
    .catch((err: AxiosError) => ({ err: err, res: { data: null } }));

export const notifyError = (err: AxiosError | any) =>
  notification.error({
    message: _get(err, 'response.data.error.message', 'Something wrong'),
  });

export const isApiError = (result: any) => result.err || !result.res;

export const api: IUriInstances = {
  base: axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: DEFAULT_HEADER_CONFIG,
  }),
};

function createInterceptors(service: string, uri_services?: string) {
  api[`${service}`].interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // Do something before request is sent
      config.headers = {
        ...config.headers,
      };
      if (Cookies.get(`${REACT_APP_APP_CLIENT_ID}.token`) && config.url !== 'v2/request-partner') {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${Cookies.get(`${REACT_APP_APP_CLIENT_ID}.token`)}`,
          // 'X-Auth-Token': Cookies.get(`${REACT_APP_APP_CLIENT_ID}.token`),
        };
      }
      if (SITE_CONFIG[getAppNameFromDomain()].requestInterceptors) {
        return SITE_CONFIG[getAppNameFromDomain()].requestInterceptors(config);
      }
      return config;
    },
    (error: AxiosError) => Promise.reject(error),
  );

  api[`${service}`].interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const reponseUrl = _get(error, 'response.config.url', '');
      if (reponseUrl.includes('/user')) {
        return Promise.reject(error);
      }

      const status: number = error.response ? error.response.status : 0;
      if ([401, 403].includes(status)) {
        window.location.replace('/logout');
        return Promise.reject('Silahkan Login lagi');
      }
      return Promise.reject(error);
    },
  );
}

export const initServicesFromCaches = () => {
  createInterceptors('base');
  if (!store.get(`${REACT_APP_APP_CLIENT_ID}__config`)) return;

  const initial = store.get(`${REACT_APP_APP_CLIENT_ID}__config`).initial;
};

export const initServices = async () => {
  const { err, res } = await to(
    api.base.get('v2/request-partner', {
      headers: {
        Authorization: 'Bearer rex_web:password',
      },
    }),
  );
  if (err || !res) {
    notifyError(err);
    return { token: null };
  }
  return res.data.data;
};

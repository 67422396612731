import { useReducer } from 'react';
import { api, to, isApiError, initServices } from 'src/api';
import API_URLS from 'src/constants/api';
import { notifyError, getAppNameFromDomain } from 'src/utils';
import Cookies from 'js-cookie';
import store from 'store2';
import { IState, ILoading, IActionTypes, IRenderView } from './types.d';
import moment from 'moment';
import { IAuthTypes } from '.';

function phoneFormatter(phone: string) {
  if (phone.slice(0, 2) === '62') {
    return `0${phone.slice(2)}`;
  }
  return phone;
}

function reducer(
  state: IState,
  action: {
    type: IActionTypes.SET_LOADING | IActionTypes.SET_PROFILE | IActionTypes.SET_STATE;
    value?: any;
    key?: string;
  },
) {
  if (action.type === IActionTypes.SET_STATE) {
    return { ...state, ...action.value };
  }
  if (action.type === IActionTypes.SET_LOADING) {
    return { ...state, loading: action.value };
  }
  throw new Error();
}

function useAction(initialState: IState) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function sendOtp(phone: string) {
    return api.base.post('/v2/auth/partner-admin/forgot-password', { email: phone });
  }

  async function validateOtp(phone: string, otp: string) {
    api.base
      .post('/v2/auth/partner-admin/verify', {
        email: phone,
        token: otp,
      })
      .then((res) => {
        dispatch({
          type: IActionTypes.SET_STATE,
          value: {
            otp_verification_id: res.data.data.token,
            renderView: IRenderView.CREATE_PASSWORD,
          },
        });
      })
      .catch((err) => {
        if (document.title === 'One Invoice') {
          err.response.data.error.message = 'Wrong verification code';
        }
        notifyError(err);
      });
  }

  async function forgotPassword(password: string) {
    dispatch({ type: IActionTypes.SET_LOADING, value: ILoading.LOGIN_FORM });
    api.base
      .post('/v2/auth/partner-admin/change-password', {
        token: state.otp_verification_id,
        password: password,
        email: state.email,
      })
      .then(() => {
        dispatch({
          type: IActionTypes.SET_STATE,
          value: { renderView: IRenderView.LOGIN_PASSWORD },
        });
        dispatch({ type: IActionTypes.SET_LOADING, value: null });
      })
      .catch((err) => {
        notifyError(err);
        dispatch({ type: IActionTypes.SET_LOADING, value: null });
        switchRenderView(IAuthTypes.IRenderView.LOGIN_PASSWORD);
      });
  }

  async function validatePhoneNumber(data: { phone: string }) {
    let formData = data;
    formData.phone = phoneFormatter(data.phone);
    dispatch({ type: IActionTypes.SET_LOADING, value: ILoading.LOGIN_FORM });

    let statusForm = '';
    if (/^\d+$/.test(formData.phone)) {
      // number
      statusForm = '/user/v2/user/phone/exist/';
    } else {
      // email
      statusForm = '/user/v2/user/check/email/';
    }
    const apiUser = await to(api.base.get(`${statusForm}${formData.phone}`));

    if (isApiError(apiUser)) {
      notifyError(apiUser.err);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return;
    }

    const { uid, is_password_exist }: any = apiUser.res.data.content;
    if (!uid) {
      notifyError(
        document.title === 'One Invoice'
          ? 'Your phone number is not registered'
          : 'Nomor ponselmu tidak terdaftar',
      );
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return;
    }

    if (!is_password_exist) {
      await sendOtp(formData.phone);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      dispatch({ type: IActionTypes.SET_STATE, value: { renderView: IRenderView.VERIFY_OTP } });
      return;
    } else {
      dispatch({
        type: IActionTypes.SET_STATE,
        value: { renderView: uid ? IRenderView.LOGIN_PASSWORD : IRenderView.VERIFY_OTP },
      });
    }

    dispatch({ type: IActionTypes.SET_LOADING, value: null });
  }

  async function login(data: { phone: string; password: string }) {
    let formData = data;
    formData.phone = phoneFormatter(data.phone);
    dispatch({ type: IActionTypes.SET_LOADING, value: ILoading.LOGIN_FORM });
    const content = await initServices();
    Cookies.set(`${process.env.REACT_APP_APP_CLIENT_ID}.token`, content.request_token);
    let params: any = '';
    if (/^\d+$/.test(formData.phone)) {
      // number
      params = {
        phone: formData.phone,
        password: formData.password,
      };
    } else {
      // email
      params = {
        email: formData.phone,
        password: formData.password,
      };
    }
    const apiUser = await to(api.base.post('/v2/auth/partner-admin', params));
    if (isApiError(apiUser)) {
      if (isApiError(apiUser).response.status === 403) {
        notifyError(isApiError(apiUser).response.data.error.message);
        dispatch({ type: IActionTypes.SET_LOADING, value: null });
        // verifikasi email
        return 403;
      } else {
        if (window.location.pathname !== '/register') {
          notifyError(
            document.title === 'One Invoice'
              ? 'Login Failed. Please check again your phone number & password'
              : 'Gagal Login. Pastikan kembali nomor ponsel & password Anda sudah benar.',
          );
        }
        dispatch({ type: IActionTypes.SET_LOADING, value: null });
        return Promise.reject();
      }
    }

    const { request_token: token }: any = apiUser.res.data.data;
    Cookies.set(`${process.env.REACT_APP_APP_CLIENT_ID}.token`, token);
    dispatch({ type: IActionTypes.SET_STATE, value: { token: token } });

    const apiUserMe = await to(api.base.get('/v2/partner-admin/me'));
    if (isApiError(apiUserMe)) {
      notifyError(apiUserMe.err);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return;
    }

    const profile: any = apiUserMe.res.data.data;
    dispatch({ type: IActionTypes.SET_STATE, value: { profile } });
    return profile;
  }

  async function updateProfile(values: any) {
    // no email for staff koala
    const { err, res } = await to(
      api.base.post(`/user/v2/user/me`, {
        user: {
          name: values.name,
          email: values.emails ? values.emails[0].address : null,
          dob: moment(values.dob).format('YYYY-MM-DD'),
          gender: values.gender ? values.gender : null,
          emails: values.emails ? values.emails : null,
        },
      }),
    );

    const { token }: any = res.data.content;
    Cookies.set(`${process.env.REACT_APP_APP_CLIENT_ID}.token`, token);
    dispatch({ type: IActionTypes.SET_STATE, value: { token: token } });

    const apiUserMe = await to(api.base.get('/user/v2/user/me'));
    if (isApiError(apiUserMe)) {
      notifyError(apiUserMe.err);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return;
    }

    const { profile }: any = apiUserMe.res.data.content;
    dispatch({ type: IActionTypes.SET_STATE, value: { profile } });
    return profile;
  }

  async function updatePhone(values: any) {
    const apiUserPhone = await to(api.base.put('/user/v2/user/phone', values));
    if (apiUserPhone.err) {
      notifyError(apiUserPhone.err);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return false;
    }
    const { token }: any = apiUserPhone.res.data.content;
    Cookies.set(`${process.env.REACT_APP_APP_CLIENT_ID}.token`, token);
    dispatch({ type: IActionTypes.SET_STATE, value: { token: token } });

    const apiUserMe = await to(api.base.get('/user/v2/user/me'));
    if (isApiError(apiUserMe)) {
      notifyError(apiUserMe.err);
      dispatch({ type: IActionTypes.SET_LOADING, value: null });
      return;
    }

    const { profile }: any = apiUserMe.res.data.content;
    dispatch({ type: IActionTypes.SET_STATE, value: { profile } });
    return profile;
  }

  function switchRenderView(renderView: string) {
    dispatch({ type: IActionTypes.SET_STATE, value: { renderView } });
  }

  function logout() {
    Cookies.remove(`${process.env.REACT_APP_APP_CLIENT_ID}.token`);
    Cookies.remove(`${process.env.REACT_APP_APP_CLIENT_ID}.uuid`);
    setTimeout(() => {
      window.localStorage.clear();
      window.location.replace('/login');
    }, 1000);
    return;
  }

  function setIsAuth(auth: boolean) {
    dispatch({ type: IActionTypes.SET_STATE, value: { isAuth: auth } });
  }

  function setEmailValue(email: string) {
    dispatch({ type: IActionTypes.SET_STATE, value: { email: email } });
  }

  return {
    state,
    action: {
      validatePhoneNumber,
      switchRenderView,
      setIsAuth,
      validateOtp,
      forgotPassword,
      sendOtp,
      login,
      logout,
      updateProfile,
      updatePhone,
      setEmailValue,
    },
  };
}

export default useAction;

import { IRolesExploration } from '../../interfaces/user/types.d';

export default {
  [`${IRolesExploration.SUPER_ADMIN}`]: {
    SIDEBAR: {
      'list-invoice': true,
      admission: true,
      university: true,
    },
    'list-invoice': {
      list: {
        menus: {
          search: true,
          upload: false,
          download: false,
        },
      },
    },
    admission: {
      list: {
        menus: {
          search: false,
          upload: false,
          download: false,
        },
      },
    },
    university: {
      list: {
        menus: {
          search: true,
          upload: false,
          download: false,
        },
      },
    },
  },
  [`${IRolesExploration.STAFF_ADMIN}`]: {
    SIDEBAR: {
      'list-invoice': true,
      admission: true,
      university: false,
    },
    'list-invoice': {
      list: {
        menus: {
          search: true,
          upload: false,
          download: false,
        },
      },
    },
    admission: {
      list: {
        menus: {
          search: false,
          upload: false,
          download: false,
        },
      },
    },
  },
};

export enum IRolesEliving {
  ADMIN = 'ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  KEUANGAN = 'KEUANGAN',
  OPERASIONAL = 'OPERASIONAL',
  STAFF_OPERASIONAL = 'STAFF_OPERASIONAL',
  GUEST = 'GUEST',
  MARKETING = 'MARKETING',
  ENGINEER = 'ENGINEER',
}

export enum IRolesProspeku {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum IRoles {
  ADMIN = 'ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
}

export enum IRolesRuangmom {
  ADMIN = 'ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
  STAFF_ADMIN_RUANGMOM = 'STAFF_ADMIN_RUANGMOM',
  SUPER_ADMIN = 'SUPER_ADMIN',
  GENERAL_ADMINISTRATOR = 'GENERAL_ADMINISTRATOR',
}

export enum IRolesPintuKelas {
  TEACHER = 'TEACHER',
  STAFF_ADMIN = 'STAFF_ADMIN',
  STAFF_FINANCE = 'STAFF_FINANCE',
  GENERAL_ADMINISTRATOR = 'GENERAL_ADMINISTRATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum IRolesSetia {
  ADMIN = 'ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
  STAFF_ADMIN_TOPUP = 'STAFF_ADMIN_TOPUP',
  STAFF_ADMIN_SETIA = 'STAFF_ADMIN_SETIA',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_ADMIN_TENANT = 'TENANT_ADMIN',
  GENERAL_ADMINISTRATOR = 'GENERAL_ADMINISTRATOR',
  STAFF_CASHIER = 'STAFF_CASHIER',
  STAFF_OUTLET = 'STAFF_OUTLET',
  STAFF_FINANCE = 'STAFF_FINANCE',
  STAFF_MARKETING = 'STAFF_MARKETING',
}
export enum IRolesOneInvoice {
  MERCHANT = 'MERCHANT',
  GENERAL_ADMINISTRATOR = 'GENERAL_ADMINISTRATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum IRolesPvj {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum IRolesSupplyChain {
  SUPER_ADMIN = 'SUPER_ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
}

export enum IRolesExploration {
  SUPER_ADMIN = 'SUPER_ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
}

export enum IRolesZoya {
  ADMIN = 'ADMIN',
  STAFF_ADMIN = 'STAFF_ADMIN',
  STAFF_ADMIN_TOPUP = 'STAFF_ADMIN_TOPUP',
  STAFF_ADMIN_SETIA = 'STAFF_ADMIN_SETIA',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_ADMIN_TENANT = 'TENANT_ADMIN',
  GENERAL_ADMINISTRATOR = 'GENERAL_ADMINISTRATOR',
  STAFF_CASHIER = 'STAFF_CASHIER',
  STAFF_OUTLET = 'STAFF_OUTLET',
  STAFF_FINANCE = 'STAFF_FINANCE',
  STAFF_MARKETING = 'STAFF_MARKETING',
}
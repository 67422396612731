import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './pageNotFound.less';

export default function PageError() {
  return (
    <div className="page-404" style={{ margin: 0 }}>
      <section className="page_404" style={{ height: '100vh' }}>
        <div className="container">
          <div className="row">
            <div className=" ">
              <div className="">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">500 Error!</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">something just isn't right...</h3>
                  <p>Looks like we're having some server issues.</p>
                  <Button icon={<ArrowLeftOutlined />} type="primary" href="/" size="large">
                    Go to Home
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

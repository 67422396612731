import React, { useContext } from 'react';
import { Typography, Card, Spin } from 'antd';
import { AuthenticationContext } from 'src/modules/authentication';
import { LoadingOutlined } from '@ant-design/icons';
import '../Login/login.less';

function Logout() {
  const auth = useContext(AuthenticationContext);
  auth.action.logout();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  window.sessionStorage.removeItem('newRegister');
  return (
    <div
      className={window.location.hostname.includes('prospeku') ? 'login-prospeku' : 'login-page'}
    >
      <Card className="login-card">
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            <br />
            <div style={{ fontSize: '25px', fontWeight: 700 }}>Signing Out</div>
            <div style={{ fontSize: '70px', fontWeight: 700 }}>
              <Spin size="large" indicator={antIcon} />
            </div>
            <Typography.Text>Please wait...</Typography.Text>
          </div>
        </div>
        <br />

      </Card>
    </div>
  );
}

export default Logout;

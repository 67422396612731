/* eslint-disable react/no-render-return-value */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import uuid from 'uuid/v4';
import App from 'src/App';
import * as serviceWorker from './registerServiceWorker';
import ConfigProvider from 'src/modules/config';
import AuthenticationProvider from 'src/modules/authentication';
import AclProvider from 'src/modules/acl';
import { initServicesFromCaches } from 'src/api';
import {RecoilRoot} from 'recoil'
import 'src/styles/style.less';

if (window.location.host.includes('prospeku')) {
  require('src/styles/disablehighligted.less')
}

const { REACT_APP_APP_CLIENT_ID } = process.env;

if (!Cookies.get(`${REACT_APP_APP_CLIENT_ID}.uuid`)) {
  Cookies.set(`${REACT_APP_APP_CLIENT_ID}.uuid`, uuid());
}

initServicesFromCaches();

const render = (Component) =>
  ReactDOM.render(
    <ConfigProvider>
      <AuthenticationProvider>
        <AclProvider>
          <RecoilRoot>
            <Router history={createBrowserHistory()}>
              <Component />
            </Router>
          </RecoilRoot>
        </AclProvider>
      </AuthenticationProvider>
    </ConfigProvider>,
    document.getElementById('root'),
  );

render(App);

if (module.hot) {
  module.hot.accept(['./App'], () => render(App));
}

if (process.env.NODE_ENV === 'production') {
  serviceWorker.unregister();
}

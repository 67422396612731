import React from 'react';
import { Menu, Row, Col, Typography } from 'antd';
import { UserOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import MainLayout from './MainLayout';

function ProfileLayout(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <MainLayout>
      <div style={{ paddingTop: '30px' }}>
        <Row>
          <Col span={7} className="sidebar-menu">
            <Menu
              onClick={(e) => {
                history.push(e.key);
              }}
              style={{ width: 256 }}
              defaultSelectedKeys={[location.pathname]}
              mode="inline"
              style={{ paddingTop: '20px' }}
            >
              <Menu.Item disabled>
                <Typography.Title style={{ paddingTop: '0px' }} level={3}>
                  Profil
                </Typography.Title>
              </Menu.Item>
              <Menu.Item key="/setting/profile">
                <UserOutlined />
                Ubah Profile
              </Menu.Item>
              {/* <Menu.Item key="/setting/change-phone-number">
                <PhoneOutlined />
                Ubah Nomor Handpone
              </Menu.Item> */}
              <Menu.Item key="/setting/change-password">
                <LockOutlined />
                Ubah Password
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={17}>
            <div style={{ marginLeft: '50px' }}>{props.children}</div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default ProfileLayout;

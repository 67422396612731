import React, { useContext, useState } from 'react';
import { ITypeInput } from 'src/interfaces/edit/types.d';
import _get from 'lodash/get';
import _kebabCase from 'lodash/kebabCase';
import { api } from 'src/api';
import moment from 'moment';
//@ts-ignore
import OtpInput from 'react-otp-input';
import { ConfigContext } from 'src/modules/config';

import { useHistory } from 'react-router-dom';
import {
  Row,
  Input,
  Card,
  Switch,
  Upload,
  Button,
  Col,
  Form,
  Select,
  message,
  InputNumber,
  DatePicker,
  PageHeader,
} from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined, InboxOutlined } from '@ant-design/icons';

import { Form as FormFinal, Field } from 'react-final-form';
const ModalSearch = React.lazy(() => import('./ModalSearch'));

function FormGenerator(props: any) {
  const { config, initialValues, validation, onSubmit } = props;
  const [loadingUpload, setLoadingUpload] = useState<any>({});
  const configCtx = useContext(ConfigContext);
  const history = useHistory();
  return (
    <div>
      <PageHeader title={config.label} onBack={() => window.location.replace(config.backTo) } /> {/* history.push(config.backTo) */}
      <FormFinal
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validation}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} style={config.cardStyles || {}}>
              <Row gutter={[30, 0]}>
                {config.forms.map((formItem: any, index: number) => {
                  const colSpan = configCtx.state.isMobileSite ? 24 : 24 / formItem.span || 24;
                  if (formItem.component) {
                    return (
                      <Col key={index} span={colSpan} style={{ marginBottom: '20px' }}>
                        {formItem.component}
                      </Col>
                    );
                  }
                  return (
                    <Col key={index} span={colSpan} style={{ marginBottom: '20px' }}>
                      <Card title={formItem.label}>
                        {formItem.list.map((list: any, indexList: number) => {
                          const key = [index, indexList].toString();
                          if (typeof list.hidden === 'boolean' && list.hidden) return null;
                          if (typeof list.hidden === 'function' && list.hidden(values)) return null;
                          if (list.component) {
                            return <div key={indexList}>{typeof list.component === 'function' ? list.component(form) : list.component}</div>;
                          }
                          if (list.inputType === ITypeInput.DYNAMIC_FORM) {
                            return (
                              <div key={indexList} style={list.styles || {}}>
                                {list.forms.map((dynamicFormData: any, formsIndex: number) => {
                                  return (
                                    <Field
                                      key={formsIndex}
                                      name={`${list.dataIndex}[${formsIndex + 1}]`}
                                    >
                                      {({ input, meta }) => {
                                        const isError = meta.error && meta.touched;
                                        return (
                                          <Form.Item
                                            label={list.label}
                                            validateStatus={isError ? 'error' : 'success'}
                                            help={isError && meta.error}
                                          >
                                            <div style={{ display: 'flex' }}>
                                              {dynamicFormData.component(input, values)}
                                              <button
                                                type="button"
                                                onClick={() => list.onRemove(formsIndex + 1)}
                                                style={{ marginLeft: '10px' }}
                                              >
                                                <DeleteOutlined style={{ fontSize: '20px' }} />
                                              </button>
                                            </div>
                                          </Form.Item>
                                        );
                                      }}
                                    </Field>
                                  );
                                })}
                                <div style={{ marginTop: '15px' }}>
                                  <Button
                                    htmlType="button"
                                    type="dashed"
                                    size="large"
                                    onClick={() => list.onAdd(list.forms.length + 1)}
                                  >
                                    <PlusOutlined /> {list.addFieldLabel || 'Add field'}
                                  </Button>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <Field key={key} name={list.dataIndex}>
                              {({ input, meta }) => {
                                const isError = meta.error && meta.touched;
                                function onChange(value: any) {
                                  input.onChange(value);
                                  if (typeof list.onChange === 'function') {
                                    list.onChange(value, form);
                                  }
                                }

                                return (
                                  <Form.Item
                                    label={list.label}
                                    validateStatus={isError ? 'error' : 'success'}
                                    required={list.required || false}
                                    help={isError && meta.error}
                                  >
                                    {list.inputType === ITypeInput.CURRENCY && (
                                      <div>
                                        <InputNumber
                                          defaultValue={_get(values, list.dataIndex, '')}
                                          formatter={(value) => {
                                            const newVal = `${value || ''}`.replace(/\D/g, '');
                                            return `Rp. ${newVal}`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ',',
                                            );
                                          }}
                                          parser={(value: any) =>
                                            value.replace(/\Rp.\s?|(,*)/g, '')
                                          }
                                          style={{ width: '100%' }}
                                          size="large"
                                          onChange={onChange}
                                        />
                                      </div>
                                    )}
                                    {list.inputType === ITypeInput.NUMBER && (
                                      <div>
                                        <InputNumber
                                          {...input}
                                          {...list.inputProps}
                                          defaultValue={_get(values, list.dataIndex, '')}
                                          formatter={(value) => {
                                            const newVal = `${value || ''}`.replace(/\D/g, '');
                                            return `${newVal}`;
                                          }}
                                          parser={(value: any) =>
                                            value.replace(/\s?|(,*)/g, '')
                                          }
                                          style={{ width: '100%' }}
                                          size="large"
                                          disabled={list.disabled}
                                          onChange={onChange}
                                          placeholder={list.placeholder}
                                        />
                                      </div>
                                    )}
                                    {list.inputType === ITypeInput.DATE_PICKER && (
                                      <div>
                                        <DatePicker
                                          size="large"
                                          style={{ width: '100%' }}
                                          value={
                                            _get(values, list.dataIndex)
                                              ? moment(
                                                  _get(
                                                    values,
                                                    list.dataIndex,
                                                    moment().format('DD MMM YYYY'),
                                                  )
                                                )
                                              : null
                                          }
                                          format={'DD MMM YYYY'}
                                          {...list.inputProps}
                                          onChange={(e, val) => {
                                            return input.onChange(val);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {list.inputType === ITypeInput.MODAL_SEARCH && (
                                      <React.Suspense fallback={<div>Loading...</div>}>
                                        <ModalSearch input={input} values={values} {...list} />
                                      </React.Suspense>
                                    )}
                                    {list.inputType === ITypeInput.SWITCH && (
                                      <span style={{ marginRight: '15px' }}>
                                        <Switch
                                          onChange={onChange}
                                          defaultChecked={_get(
                                            values,
                                            list.dataIndex,
                                            input.defaultValue,
                                          )}
                                        />
                                      </span>
                                    )}

                                    {list.inputType === ITypeInput.TEXT && (
                                      <Input
                                        size="large"
                                        {...input}
                                        {...list.inputProps}
                                        disabled={list.disabled}
                                        onChange={(event) => onChange(event.target.value)}
                                        value={_get(values, list.dataIndex, '')}
                                        placeholder={list.placeholder}
                                      />
                                    )}
                                    {list.inputType === ITypeInput.SLUG && (
                                      <div>
                                        <Input
                                          size="large"
                                          {...input}
                                          disabled
                                          type="text"
                                          value={_kebabCase(
                                            _get(values, list.referenceDataIndex, ''),
                                          )}
                                          placeholder={list.placeholder}
                                        />
                                        <div></div>
                                      </div>
                                    )}

                                    {list.inputType === ITypeInput.OTP && (
                                      <div style={{ width: '400px' }}>
                                        <OtpInput
                                          onChange={(otp: any) => onChange(otp)}
                                          numInputs={6}
                                          value={values[`${list.dataIndex}`]}
                                          containerStyle="otp-input"
                                          // inputStyle="ant-input ant-input-lg"
                                          separator={<span style={{ padding: '7px' }}>-</span>}
                                        />
                                      </div>
                                    )}

                                    {list.inputType === ITypeInput.PASSWORD && (
                                      <Input.Password
                                        size="large"
                                        {...input}
                                        onChange={onChange}
                                        placeholder={list.placeholder}
                                      />
                                    )}

                                    {list.inputType === ITypeInput.TEXTAREA && (
                                      <Input.TextArea
                                        {...input}
                                        style={{ minHeight: '180px' }}
                                        onChange={onChange}
                                        placeholder={list.placeholder}
                                      />
                                    )}

                                    {list.inputType === ITypeInput.IMG && (
                                      <div>
                                        <Upload.Dragger
                                          name="file"
                                          multiple={false}
                                          accept="image/*"
                                          {...list.inputProps}
                                          customRequest={(options: any) => {
                                            const bodyFormData = new FormData();
                                            bodyFormData.append('file', options.file);
                                            return api.base
                                              .post(list.endPoint, bodyFormData, {
                                                headers: { 'content-type': 'multipart/form-data' },
                                              })
                                              .then((res) => {
                                                input.onChange(res.data.content.url);
                                                options.onSuccess(
                                                  res.data.content.url,
                                                  options.file,
                                                );
                                                message.success(
                                                  `${options.file.name} file uploaded successfully.`,
                                                );
                                              })
                                              .catch((err) => {
                                                message.error(
                                                  `${options.file.name} file upload failed.`,
                                                );
                                              });
                                          }}
                                          onChange={(info: any) => {
                                            if (info.file.status === 'uploading') {
                                              setLoadingUpload({ [`${list.dataIndex}`]: true });
                                            }
                                            if (info.file.status === 'done') {
                                              setLoadingUpload({ [`${list.dataIndex}`]: false });
                                            }
                                          }}
                                          fileList={[
                                            {
                                              uid: '1',
                                              name: _get(values, list.dataIndex, null),
                                              status: 'done',
                                              size: 2000,
                                              type: 'done',
                                              url: _get(values, list.dataIndex, '#'),
                                            },
                                          ]}
                                          onRemove={() => {
                                            input.onChange(null);
                                            setLoadingUpload({ [`${list.dataIndex}`]: false });
                                          }}
                                        >
                                          {_get(values, list.dataIndex, false) ? (
                                            <img
                                              src={_get(values, list.dataIndex, '#')}
                                              alt="avatar"
                                              style={{ width: '200px' }}
                                            />
                                          ) : (
                                            <div>
                                              <p className="ant-upload-drag-icon">
                                                {loadingUpload[`${list.dataIndex}`] ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <InboxOutlined />
                                                )}
                                              </p>
                                              <p className="ant-upload-text">
                                                Click or drag file to this area to upload
                                              </p>
                                            </div>
                                          )}
                                        </Upload.Dragger>
                                      </div>
                                    )}

                                    {list.inputType === ITypeInput.PDF && (
                                      <div>
                                        <Upload.Dragger
                                          name="file"
                                          multiple={false}
                                          accept=".pdf"
                                          customRequest={(options: any) => {
                                            const bodyFormData = new FormData();
                                            bodyFormData.append('file', options.file);
                                            return api.base
                                              .post(list.endPoint, bodyFormData, {
                                                headers: { 'content-type': 'multipart/form-data' },
                                              })
                                              .then((res) => {
                                                input.onChange(res.data.content.url);
                                                options.onSuccess(
                                                  res.data.content.url,
                                                  options.file,
                                                );
                                                message.success(
                                                  `${options.file.name} file uploaded successfully.`,
                                                );
                                              })
                                              .catch((err) => {
                                                message.error(
                                                  `${options.file.name} file upload failed.`,
                                                );
                                              });
                                          }}
                                          onChange={(info: any) => {
                                            let fileReader = new FileReader();
                                            if (info.file.status === 'uploading') {
                                              setLoadingUpload({ [`${list.dataIndex}`]: true });
                                            }
                                            if (info.file.status === 'done') {
                                              setLoadingUpload({ [`${list.dataIndex}`]: false });
                                              fileReader.readAsText(info.fileList[0].originFileObj);
                                            }
                                          }}
                                          onRemove={() => {
                                            input.onChange(null);
                                          }}
                                        >
                                          {_get(values, list.dataIndex, false) ? (
                                            _get(values, list.dataIndex, '#')
                                          ) : (
                                            <div>
                                              <p className="ant-upload-drag-icon">
                                                {loadingUpload[`${list.dataIndex}`] ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <InboxOutlined />
                                                )}
                                              </p>
                                              <p className="ant-upload-text">
                                                Click or drag pdf file to this area to upload
                                              </p>
                                            </div>
                                          )}
                                        </Upload.Dragger>
                                      </div>
                                    )}

                                    {list.inputType === ITypeInput.SELECT && (
                                      <Select
                                        disabled={list.options.length < 1}
                                        size="large"
                                        placeholder="Pilih salah satu"
                                        {...input.value}
                                        {...list.inputProps}
                                        value={input.value || undefined}
                                        onChange={onChange}
                                      >
                                        {list.options.map((option: any, ij: number) => (
                                          <Select.Option value={option.id} key={ij}>
                                            {option.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Form.Item>
                                );
                              }}
                            </Field>
                          );
                        })}
                      </Card>
                    </Col>
                  );
                })}
                <Col span={24} style={{ marginBottom: '40px', textAlign: 'right' }}>
                  <Button
                    loading={submitting}
                    htmlType="submit"
                    type="primary"
                    size="large"
                    style={{ width: '250px' }}
                  >
                    {props.saveText || 'Simpan'}
                  </Button>
                </Col>
              </Row>
            </form>
          );
        }}
      />
    </div>
  );
}

export default FormGenerator;

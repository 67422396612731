import React from 'react';
import { api, to, notifyError } from 'src/api';
import { notify } from 'src/utils';
import { useHistory } from 'react-router-dom';
import FormGenerator from 'src/components/Form/FormGenerator';
import { ITypeInput } from 'src/interfaces/edit/types.d';

function ChangePassword() {
  const history = useHistory();
  return (
    <div>
      <FormGenerator
        validation={(values: any) => {
          let errors: any = {};
          if (!values.oldPassword) {
            errors.oldPassword = 'password lama harus di isi';
          }
          if (!values.newPassword) {
            errors.newPassword = 'password baru harus di isi';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'konfirmasi password harus di isi';
          }
          if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = 'password harus sama dengan konfirmasi password';
          }
          return errors;
        }}
        onSubmit={async (values: any) => {
          const { err, res } = await to(
            api.base.post('/user/v2/user/password/verify', { password: values.oldPassword }),
          );
          if (err) {
            notifyError(err);
            return;
          }
          const response = await to(
            api.base.post('/user/v2/user/password', {
              password_verification_id: res.data.content.password_verification_id,
              password: values.confirmPassword,
            }),
          );
          if (response.err) {
            notifyError(response.err);
            return;
          }
          notify('Password Berhasil di perbaharui');
          if (document.title === 'One Invoice') {
            setTimeout(() => history.push('/logout'), 2000);
          }
          // history.push('/logout'); // no logout when change password, by request
        }}
        initialValues={{}}
        config={{
          label: 'Ubah Password',
          forms: [
            {
              label: `Password`,
              list: [
                {
                  label: 'Password Lama',
                  inputType: ITypeInput.PASSWORD,
                  dataIndex: 'oldPassword',
                  placeholder: 'Password lama',
                },
                {
                  label: 'Password Baru',
                  inputType: ITypeInput.PASSWORD,
                  dataIndex: 'newPassword',
                  placeholder: 'Password baru',
                },
                {
                  label: 'Konfirmasi Password',
                  inputType: ITypeInput.PASSWORD,
                  dataIndex: 'confirmPassword',
                  placeholder: 'Konfirmasi password',
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default ChangePassword;

import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router';

// PAGES
import Login from './pages/Login';
import List from './pages/List';
import Edit from './pages/Edit';
import Add from './pages/Add';
import Detail from './pages/Detail';
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
import PageNotFound from './pages/PageNotFound';
import ChangePassword from './pages/setting/change-password';
import ChangePhoneNumber from './pages/setting/change-phone-number';
import Profile from './pages/setting/profile';
import PageError from './pages/PageError';

// LAYOUTS
import MainLayout from './components/Layout/MainLayout';
import ProfileLayout from './components/Layout/ProfileLayout';
import StaticLayout from './components/Layout/StaticLayout';

import { AuthenticationContext } from './modules/authentication';

function PrivateRoute({ component: Component, ...props }) {
  const auth = useContext(AuthenticationContext);
  if (props.children) {
    return auth.state.profile ? props.children : <Redirect to="/login" />;
  }
  return (
    <Route
      {...props}
      render={(innerProps) =>
        auth.state.profile ? <Component {...innerProps} /> : <Redirect to="/login" />
      }
    />
  );
}

function PublicRoute({ component: Component, ...props }) {
  const auth = useContext(AuthenticationContext);
  if (props.children) {
    return !auth.state.isAuth ? props.children : <Redirect to="/login" />;
  }
  if (!!auth.state.isAuth && props.location.pathname === '/login') {
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...props}
      render={(innerProps) =>
        !auth.state.isAuth ? <Component {...innerProps} /> : <Redirect to="/login" />
      }
    />
  );
}

export default (
  <Switch>
    <PublicRoute exact path={['/login', '/register', '/otp/:id']} component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/page-error" component={PageError} />
    <Route exact path="/logout" component={Logout} />
    <PrivateRoute exact path="/" component={Dashboard} />
    <PrivateRoute path={['/setting']}>
      <ProfileLayout>
        <Switch>
          <Route exact path="/setting/change-password" component={ChangePassword} />
          <Route exact path="/setting/change-phone-number" component={ChangePhoneNumber} />
          <Route exact path="/setting/profile" component={Profile} />
        </Switch>
      </ProfileLayout>
    </PrivateRoute>
    {/* <PrivateRoute path={['/setting']}>

    </PrivateRoute> */}
    <PrivateRoute path={['/list', '/edit', '/add', '/detail']}>
      <MainLayout>
        <Switch>
          <Route exact path="/edit/:page/:id" component={Edit} />
          <Route exact path="/edit/:page/:id/:business_id" component={Edit} />
          <Route exact path="/detail/:page/:id/:param" component={Detail} />
          <Route exact path="/detail/:page/:id" component={Detail} />

          <Route exact path="/add/:page/:id" component={Add} />
          <Route exact path="/add/:page/:id/:business_id" component={Add} />
          <Route exact path="/add/:page" component={Add} />

          <Route exact path="/list/:page/:id" component={List} />
          <Route exact path="/list/:page" component={List} />
        </Switch>
      </MainLayout>
    </PrivateRoute>
    <Route component={PageNotFound} />
  </Switch>
);

import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
import _camelCase from 'lodash/camelCase';
import { initServices } from 'src/api';
import { getAppNameFromDomain } from 'src/utils';
import { IState, IProps, IContext, IContentService } from './types';
import { getFromLocalStorage, saveToLocalStorage } from '../helpers';
import siteconfig from 'src/siteconfig/index';

const CONFIG: any = siteconfig;

const initialState: IState = getFromLocalStorage('config') || {
  connection: 'online',
  initial: null,
  loading: true,
  appName: '',
  isMobileSite: true,
};

export const ConfigContext = createContext<IContext>({ state: initialState });

const ConfigProvider: React.SFC<IProps> = ({ children }) => {
  const [state, setState] = useState(initialState);

    async function init() {
      if (
      Cookies.get(`${process.env.REACT_APP_APP_CLIENT_ID}.token`) &&
      Cookies.get(`${process.env.REACT_APP_APP_CLIENT_ID}.token`) !== 'null'
    ) {
      setState((prevState) => ({ ...prevState, loading: false }));
      return;
    }
    const content: IContentService = await initServices();
    Cookies.set(`${process.env.REACT_APP_APP_CLIENT_ID}.token`, content.request_token);
    setState((prevState) => ({ ...prevState, initial: content, loading: false }));
  }

  useEffect(() => {
    const appName = getAppNameFromDomain();
    document.getElementsByTagName('html')[0].classList.add(`theme-${appName}`);
    init();
    setState((prevState) => ({
      ...prevState,
      appName: _camelCase(appName).toLowerCase(),
      isMobileSite: window.matchMedia('(max-width: 768px)').matches,
    }));
  }, []);

  useEffect(() => saveToLocalStorage('config', state), [state]);
  if (state.loading) return null;

  return (
    <ConfigContext.Provider value={{ state }}>
      {CONFIG[`${state.appName}`].helmet || null}
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

import React, { useContext } from 'react';
import siteconfig from 'src/siteconfig';
import { useParams } from 'react-router-dom';
import { ConfigContext } from 'src/modules/config';
import { AclContext } from 'src/modules/acl';
import EmptyState404 from 'src/pages/PageNotFound';

const SITE_CONFIG: any = siteconfig;

function List() {
  const configCtx = useContext(ConfigContext);
  const acl = useContext(AclContext);
  const params: { page?: string; id?: string } = useParams();
  const activePlatforms = SITE_CONFIG[`${configCtx.state.appName}`][`${params.page}`];
  const isEligible = acl.action.checkEligibility('SIDEBAR.');
  
  if (!activePlatforms || !activePlatforms.list || !isEligible(params.page)) {
    return <EmptyState404 />;
  }
  return <div>{activePlatforms.list}</div>;
}

export default List;

import store from 'store2';

export const saveToLocalStorage = (key: string, data: any) => {
  store.set(`${process.env.REACT_APP_APP_CLIENT_ID}__${key}`, data);
};

export const getFromLocalStorage = (key: string) => {
  if (store.has(`${process.env.REACT_APP_APP_CLIENT_ID}__${key}`)) {
    return store.get(`${process.env.REACT_APP_APP_CLIENT_ID}__${key}`);
  }
  return null;
};

export const actionCreator = (actions: Function, state: any, setState: Function) => {
  const updateState = (newState: object) =>
    setState((prevState: any) => ({
      ...prevState,
      ...newState,
    }));
  const newAction: any = {};
  const action = actions({ state, updateState });
  Object.keys(action).forEach((item: string) => {
    newAction[item] = (payload: object) => action[item]({ state, updateState, payload });
  });
  return newAction;
};

import _get from 'lodash/get';
import React from 'react';
import moment from 'moment';
import { notification, Button, message } from 'antd';

export function getAppNameFromDomain() {
  const hostname = window.location.hostname.split('.');
  let appName = hostname.length > 2 ? hostname[1] : hostname[0];

  if (['localhost', 'e-living', 'onelabs', 'koala'].includes(appName)) {
    appName = 'eliving';
  }
  if (window.location.hostname.split('-cms-').length > 1) {
    appName = window.location.hostname.split('-cms-')[0];
  }
  if (['bisakuliah', 'beasisone'].includes(appName)) {
    appName = 'beasisone';
  }
  if (window.location.host.includes('one-invoice')) {
    appName = 'oneinvoice';
  }
  if (window.location.host.includes('supply-chain')) {
    appName = 'supplychain';
  }
  if (window.location.host.includes('exploration')) {
    appName = 'exploration';
  }
  return appName;
}

export const dateFormat = (date, format) => {
  return format ? moment(date).format(format) : moment(date);
};

export const toRupiah = (value) =>
  'Rp ' +
  Number((value || 0).toFixed(1))
    .toLocaleString()
    .replace(/,/g, '.');

export const getUnixTime = (date) => {
  if (date) {
    return moment(date).unix();
  }
  return moment().unix();
};

export const mockArray = (total) => Array(total).fill(1);

export const notifyError = (err) =>
  notification.error({
    message: _get(
      err,
      'response.data.error.message',
      typeof err === 'string' ? err : 'Something went wrong',
    ),
  });

export const notifyErrorMessage = (message, duration = 0) => {
  notification.error({
    message: typeof message === 'string' ? message : 'Something went wrong',
    duration: duration,
  });
};

export const notify = (msg) =>
  notification.success({
    message: msg,
  });

export const notifyWithButtonClose = (msg) => {
  const key = `success${Date.now()}`;

  const buttonClose = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Close
    </Button>
  );

  notification.success({
    key,
    description: 'File has been successfully uploaded',
    message: msg,
    duration: 0,
    btn: buttonClose,
  });
};

export const notifyErrorWithButtonClose = (err) => {
  const key = `error${Date.now()}`;

  const buttonClose = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Close
    </Button>
  );

  notification.error({
    key,
    message: _get(
      err,
      'response.data.error.message',
      typeof err === 'string' ? err : 'Something went wrong',
    ),
    duration: 0,
    btn: buttonClose,
  });
};

export function getFirstArray(res) {
  let data = [];
  if (!Array.isArray(res.data.content)) {
    const childKey = Object.keys(res.data.content).find((key) =>
      Array.isArray(res.data.content[key]),
    );
    if (childKey) {
      data = res.data.content[`${childKey}`];
    }
  } else {
    data = res.data.content;
  }
  return data;
}

export function formatDate(d) {
  const date = new Date(d * 1000);
  if (isNaN(date.getTime())) {
    return d;
  } else {
    let month = new Array();
    month[0] = 'Januari';
    month[1] = 'Februari';
    month[2] = 'Maret';
    month[3] = 'April';
    month[4] = 'Mei';
    month[5] = 'Juni';
    month[6] = 'Juli';
    month[7] = 'Agustus';
    month[8] = 'September';
    month[9] = 'Oktober';
    month[10] = 'November';
    month[11] = 'Desember';

    let day = date.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    return day + ' ' + month[date.getMonth()] + ' ' + date.getFullYear();
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

import React from 'react';
import { Helmet } from 'react-helmet';
import eligibilityAcl from './acl';
import './index.less';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';

function LazyLoad(props: any) {
  const { component: Component, ...rest } = props;
  return (
    <div>
      <React.Suspense fallback={<div />}>
        <Component {...rest} />
      </React.Suspense>
    </div>
  );
}

export default {
  pageTitle: 'Exploration',
  helmet: (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="shortcut icon" href="/images/exploration-logo.png" />
      <title>Exploration Dashboard</title>
    </Helmet>
  ),
  eligibility: eligibilityAcl,
  allowRoles: ['SUPER_ADMIN', 'STAFF_ADMIN'],
  overrideMenus: true,
  admission: {
    list: <LazyLoad component={React.lazy(() => import('./pages/admission'))} />,
    add: <LazyLoad component={React.lazy(() => import('./pages/admission/add'))} />,
  },
  university: {
    list: <LazyLoad component={React.lazy(() => import('./pages/university'))} />,
    add: <LazyLoad component={React.lazy(() => import('./pages/university/add'))} />,
  },
  invoice: {
    list: <LazyLoad component={React.lazy(() => import('./pages/invoice'))} />,
  },
  menus: [
    {
      key: 'admission',
      name: 'Buka Admission Baru',
      icon: <HomeOutlined />,
    },
    {
      key: 'invoice',
      name: 'Lihat Pendaftar Admission',
      icon: <HomeOutlined />,
    },
    {
      key: 'university',
      name: 'Tambah Universitas',
      icon: <UserOutlined />,
    },
  ],
};

export const DATE_FORMAT: any = {
  DATE: 'DD MMMM YYYY',
  DATE_TIME: 'DD MMM YYYY hh:mm:ss a',
  YEAR: 'YYYY',
};

export const STATUS: any = {
  '0': {
    label: 'Tidak Aktif',
    color: 'grey',
  },
  '1': {
    label: 'Aktif',
    color: 'green',
  },
};

export const STATUS_QUESTION: any = {
  '0': {
    label: 'Tidak',
    color: 'grey',
  },

  '1': {
    label: 'Ya',
    color: 'green',
  },
};

export const STATUS_INVOICES: any = {
  '1': {
    label: 'Pending',
    color: 'orange',
  },
  '2': {
    label: 'Paid',
    color: 'green',
  },
  '3': {
    label: 'Cancelled',
    color: 'red',
  },
  '4': {
    label: 'Refunded',
    color: 'blue',
  },
};

const CLIENTS: { [key: string]: string } = {
  eliving: 'eliving.web',
  workfit: 'workfit.web',
  pintuaktif: 'pintuaktif.web',
  akuaktif: 'akuaktif.web',
  ruangmom: 'ruangmom.web',
  prospeku: 'prospeku.web',
  pintukelas: 'pintukelas.cms',
  beasisone: 'beasisone.web',
  bisakuliah: 'beasisone.web',
  setia: 'setia.pwa',
  oneinvoice: 'oneinvoice.cms',
  'one-invoice': 'oneinvoice.cms',
  pvj: 'setia.pwa',
  zoya: 'setia.pwa',
};

export const CLIENT_ID = (hostname: string) => {
  let merchant_id: string = '';
  Object.keys(CLIENTS).forEach(function (key) {
    if (hostname.includes(key)) {
      merchant_id = CLIENTS[key];
    }
  });
  return merchant_id;
};

import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _set from 'lodash/set';
import FormGenerator from 'src/components/Form/FormGenerator';
import { ITypeInput } from 'src/interfaces/edit/types.d';
import { to, api, notifyError } from 'src/api';
import { notify } from 'src/utils';
import { AuthenticationContext } from 'src/modules/authentication';

function Profile() {
  const [state, setstate] = useState({
    loading: false,
    profile: { name: null, emails: [{ address: null }] },
    error: ''
  });
  const authCtx = useContext(AuthenticationContext);
  useEffect(() => {
    init();
  }, []);
  async function init() {
    const { err, res } = await to(api.base.get('/user/v2/user/me'));
    if (err) {
      notifyError(err);
      return;
    }
    setstate({ ...state, profile: res.data.content.profile });
  }
  function validation(values: any) {
    let errors: any = {};
    if (!values.name) {
      errors.name = 'Nama Harus di isi';
    }
    if (values.name && values.name.length > 30) {
      errors.name = 'Maksimal 30 karakter';
    }
    if (values.emails === undefined || !values.emails[0].address) {
      _set(errors, 'emails[0].address', 'Email Harus di isi');
    }
    if (!values.dob) {
      errors.dob = 'Tanggal Lahir Harus di isi';
    }
    // if (!values.gender) {
    //   errors.gender = 'Jenis Kelamin Harus di isi';
    // }
    return errors;
  }

  async function onSubmit(values: any) {
    setstate({ ...state, loading: true });
    try {
      await authCtx.action.updateProfile(values);
      authCtx.action.setIsAuth(true);
      notify('Profile updated.');
    } catch (error) {
      setstate({ ...state, loading: false });
    }
    return;
  }

  return (
    <div>
      {/* <PageHeader title="Ubah Password" onBack={() => history.push('/')} subTitle="e-Living" />
      <Card title="Password"></Card> */}
      <FormGenerator
        validation={validation}
        // onBlur={}
        onSubmit={onSubmit}
        initialValues={state.profile}
        config={{
          label: 'Ubah Profil',
          forms: [
            {
              label: `Akun`,
              list: [
                {
                  label: 'Nama',
                  inputType: ITypeInput.TEXT,
                  dataIndex: 'name',
                  placeholder: 'Nama Tenant',
                },
                {
                  label: 'Email',
                  inputType: ITypeInput.TEXT,
                  dataIndex: 'emails[0].address',
                  placeholder: 'Email',
                  allowClear: true,
                },
                {
                  label: 'Tanggal Lahir',
                  inputType: ITypeInput.DATE_PICKER,
                  dateFormat: 'DD/MM/YYYY',
                  dataIndex: 'dob',
                  placeholder: 'Tanggal Lahir',
                },
                {
                  label: 'Jenis Kelamin',
                  inputType: ITypeInput.SELECT,
                  dataIndex: 'gender',
                  options: [
                    { id: 1, name: 'Male' },
                    { id: 2, name: 'Female' },
                  ],
                  placeholder: 'Jenis Kelamin',
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default Profile;

import _get from 'lodash/get';
import _set from 'lodash/set';

export enum IRules {
  'REQUIRED' = 'required',
  'MUST_BE_NUMBER' = 'mustBeNumber',
  'MUST_BE_STRING' = 'mustBeString',
  'CELLPHONE' = 'cellphone',
  'EMAIL' = 'email',
  'ALPHABETS' = 'alphabets',
  'ALPHANUMERICSPACE' = 'alphanumericSpace',
  'MATCH_PASSWORD' = 'matchPassword',
  'MIN_VALUE' = 'minValue',
  'MAX_VALUE' = 'maxValue',
  'MIN_VALUE_NUMBER' = 'minValueNumber',
  'MAX_VALUE_NUMBER' = 'maxValueNumber',
  'CHECKED' = 'checked',
}

const validate: any[string] = {
  required: (value: any, appName?) => {
    if (value && value.length < 1) {
      return appName && (appName === 'oneinvoice' || appName === 'supplychain')
        ? 'Must be filled'
        : 'Bagian ini harus diisi';
    }
    return value
      ? undefined
      : appName && (appName === 'oneinvoice' || appName === 'supplychain')
      ? 'Must be filled'
      : 'Bagian ini harus diisi';
  },

  mustBeNumber: (value: number) =>
    value && typeof value === 'number' ? undefined : 'Must be a number',

  mustBeString: (value: any) =>
    value && typeof value === 'string' ? undefined : 'Must be a string',

  alphabets: (value: any) =>
    /^[a-zA-Z ]+$/.test(value) ? undefined : 'Hanya bisa diisi dengan alfabet',

  alphanumericSpace: (value: any) =>
    /^[a-zA-Z0-9 ]*$/.test(value) ? undefined : 'Hanya bisa diisi dengan alfanumerik',

  cellphone: (value: string) =>
    /^$|^(08|62|02)[0-9]{3,12}$/.test(value)
      ? undefined
      : 'You have entered an invalid cellphone number',

  email: (value: string) =>
    /\S+@\S+\.\S+/.test(value) ? undefined : 'You have entered an invalid e-mail address',

  matchPassword: (pass1: string) => (pass2: string) =>
    pass1 !== pass2 ? undefined : 'Password you entered do not match',

  minValue: (value: string, min: number) =>
    value && value.length >= min ? undefined : `Minimal ${min} karakter`,

  maxValue: (value: string, max: number) =>
    value && value.length <= max ? undefined : `Maksimal ${max} karakter`,

  minValueNumber: (value: number, min: number) =>
    value && value.toString().length >= min ? undefined : `Minimal ${min} angka`,

  maxValueNumber: function (value: any, max: number) {
    if ((value && typeof value == 'string') || typeof value == 'number') {
      if (value == 0) {
        return undefined;
      }
      return value && value.toString().length <= max ? undefined : `Maksimal ${max} angka`;
    } else return undefined;
  },

  checked: (value: boolean) => (value == true ? undefined : 'Must be checked'),
};

export const metaError = (meta: any | { error: string; touched: any }) =>
  meta.error && meta.touched;

export const validators = (validation: string, source?) => (value: any) => {
  return validate[validation](value, source) || undefined;
};

export const validateRules = (errors: object, values: object) => (
  objectKey: string,
  rule: string | string[],
  params?: any,
) => {
  const value = _get(values, objectKey, undefined);
  let errorMessage = null;
  if (typeof rule === 'string') {
    errorMessage = validate[rule](value, params);
  } else {
    rule.some((rule: string) => {
      errorMessage = validate[rule](value, params);
    });
  }

  if (errorMessage) {
    _set(errors, objectKey, errorMessage);
  }
};

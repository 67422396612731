/* eslint-disable global-require */
import React, { useEffect } from 'react';
import routes from './routes';

function App() {
  useEffect(() => {
    const elem = document.getElementById('startingLoader');

    window.onload = () => {
      if (elem) {
        elem.remove();
      }
    };
  }, []);
  return (
    <div className="App" style={{ minHeight: '100vh' }}>
      {routes}
    </div>
  );
}

export default App;

import React, { useEffect, useState, useContext } from 'react';
import { PageHeader, Typography, Button, Input, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import FormGenerator from 'src/components/Form/FormGenerator';
import { api, to, notifyError } from 'src/api';
import { notify } from 'src/utils';
import { ITypeInput } from 'src/interfaces/edit/types.d';
import { AuthenticationContext } from 'src/modules/authentication';

enum IStep {
  STEP_ONE = 'one',
  STEP_TWO = 'two',
  STEP_THREE = 'three',
  STEP_FOUR = 'four',
}

function ChangePhoneNumber() {
  const [state, setstate] = useState(IStep.STEP_ONE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordVerificationId, setPasswordVerificationId] = useState(''); // password_verification_id
  const [otpVerificationId, setOtpVerificationId] = useState(''); // otp_verification_id

  const history = useHistory();
  function validation(values: any) {
    const errors = {};
    if (!values.name) {
      //   _set(errors, 'name', 'Nama Unit is Required');
    }
    return errors;
  }
  const authCtx = useContext(AuthenticationContext);

  useEffect(() => {
    init();
  }, []);
  async function init() {
    const { err, res } = await to(api.base.get('/user/v2/user/me'));
    if (err) {
      notifyError(err);
      return;
    }
    setPhoneNumber(res.data.content.profile.phones[0].number);
  }

  async function onSubmit(values: any) {}

  if (state === IStep.STEP_ONE) {
    return (
      <div>
        <PageHeader title="Ubah Nomor Handphone" onBack={() => history.push('/')} />
        <Card title="Nomor Handphone">
          <Typography.Title level={4}>Nomor Terdaftar Anda</Typography.Title>
          <Input size="large" placeholder={phoneNumber} value={phoneNumber} disabled />
        </Card>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setstate(IStep.STEP_TWO)}
            style={{ width: '250px' }}
            type="primary"
            size="large"
          >
            Lanjutkan
          </Button>
        </div>
      </div>
    );
  }
  if (state === IStep.STEP_TWO) {
    return (
      <div>
        <FormGenerator
          validation={(values: any) => {
            let errors: any = {};
            if (!values.password) {
              errors.password = 'Password harus di isi';
            }
            return errors;
          }}
          onSubmit={async (values: any) => {
            const { err, res } = await to(
              api.base.post('/user/v2/user/password/verify', { password: values.password }),
            );
            if (err) {
              notifyError(err);
              return;
            }
            setPasswordVerificationId(res.data.content.password_verification_id);
            setstate(IStep.STEP_THREE);
          }}
          config={{
            label: 'Ubah Nomor Handphone',
            forms: [
              {
                label: `Masukkan Password`,
                list: [
                  {
                    label: 'Password',
                    inputType: ITypeInput.PASSWORD,
                    htmlType: 'password',
                    dataIndex: 'password',
                    placeholder: 'Masukkan Password Anda',
                  },
                ],
              },
            ],
          }}
          saveText="Lanjutkan"
        />
      </div>
    );
  }
  if (state === IStep.STEP_THREE) {
    return (
      <div>
        <FormGenerator
          validation={(values: any) => {
            let errors: any = {};
            if (!values.phone) {
              errors.password = 'Nomor Handphone harus di isi';
            }
            return errors;
          }}
          onSubmit={async (values: { phone: string }) => {
            const { err } = await to(
              api.base.post('/v2/auth/partner-admin/forgot-password', { email: values.phone }),
            );
            if (err) {
              notifyError(err);
              return;
            }
            setPhoneNumber(values.phone);
            setstate(IStep.STEP_FOUR);
          }}
          initialValues={{}}
          config={{
            label: 'Ubah Nomor Handphone',
            forms: [
              {
                label: `Nomor Handphone`,
                list: [
                  {
                    component: (
                      <div>
                        Silahkan masukkan nomor handphone baru untuk mendapatkan kode verifikasi
                      </div>
                    ),
                  },
                  {
                    inputType: ITypeInput.TEXT,
                    dataIndex: 'phone',
                    placeholder: 'Nomor Handphone Baru',
                  },
                ],
              },
            ],
          }}
          saveText="Lanjutkan"
        />
      </div>
    );
  }
  return (
    <div>
      <FormGenerator
        validation={validation}
        onSubmit={async (values: { otp: string }) => {
          const { err, res } = await to(
            api.base.post('/v2/auth/partner-admin/verify', {
              email: phoneNumber,
              token: values.otp,
            }),
          );
          if (err) {
            notifyError(err);
            return;
          }
          setOtpVerificationId(res.data.content.otp_verification_id);
          const updatePhone = {
            phone: phoneNumber,
            password_verification_id: passwordVerificationId,
            otp_verification_id: res.data.content.otp_verification_id, // otpVerificationId,
          };
          try {
            const updatePhoneNumber: any = await authCtx.action.updatePhone(updatePhone);
            if (!updatePhoneNumber) {
              // 500
            } else {
              authCtx.action.setIsAuth(true);
              notify('Nomor handphone berhasil diubah');
              history.push(`/setting/profile`);
            }
          } catch (error) {
            console.log(error);
          }
          return;
        }}
        initialValues={{}}
        saveText="Lanjutkan"
        config={{
          label: 'Ubah Nomor Handphone',
          forms: [
            {
              label: `Verifikasi Nomor Handphone`,
              list: [
                {
                  component: (
                    <div>
                      Masukkan kode verifikasi yang baru saja kami kirimkan ke nomor handphone Anda
                      ({phoneNumber}). Kode ini valid untuk 5 menit
                    </div>
                  ),
                },
                {
                  label: 'Kode verifikasi',
                  inputType: ITypeInput.OTP,
                  dataIndex: 'otp',
                  placeholder: 'Nomor Handphone Baru',
                },
                {
                  component: (
                    <Button
                      onClick={async () => {
                        const { err } = await to(
                          api.base.post('/v2/auth/partner-admin/forgot-password', {
                            email: phoneNumber,
                          }),
                        );
                        if (err) {
                          notifyError(err);
                          return;
                        }
                        notify('Kode verifikasi berhasil di kirim ulang');
                      }}
                      type="ghost"
                    >
                      Kirim Ulang ?
                    </Button>
                  ),
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default ChangePhoneNumber;

import React, { useEffect, createContext } from 'react';
import useAction from './useAction';
import { IState, IAction } from './types.d';
import { getFromLocalStorage, saveToLocalStorage } from '../helpers';
import siteconfig from 'src/siteconfig/index';

const CONFIG: any = siteconfig;

interface IProvider {
  state: IState;
  action: IAction;
}

const initialState = getFromLocalStorage('acl') || {
  aclList: [],
  platforms: '',
  activePlatforms: null,
  eligibility: null,
};

export const AclContext = createContext<IProvider>({
  state: initialState,
  action: {
    getMenus: () => {},
    setEligibility: () => {},
    checkEligibility: () => {},
  },
});

const AclProvider: React.SFC<{ children: React.ReactNode }> = ({ children }) => {
  const store = useAction(initialState);
  const { activePlatforms } = store.state;
  const DEFAULT_ROLES = ['SUPER_ADMIN', 'STAFF_ADMIN', 'STAFF_OPERASIONAL'];

  useEffect(() => {
    saveToLocalStorage('acl', store.state);
  }, [store.state]);

  useEffect(() => {
    if (activePlatforms) {
      const allowedRoles = CONFIG[`${activePlatforms.merchant_id}`].allowedRoles;
      const activeRole = allowedRoles
        ? allowedRoles.filter((d: string) => activePlatforms.roles.includes(d))
        : DEFAULT_ROLES.filter((d: string) => activePlatforms.roles.includes(d));
      store.action.setEligibility(
        CONFIG[`${activePlatforms.merchant_id}`].eligibility[activeRole[0]],
      );
      window.sessionStorage.removeItem('firstAclMenu');
      window.sessionStorage.setItem('firstAclMenu', activePlatforms.menu[0]);
    }
  }, [store.state.activePlatforms]);
  return <AclContext.Provider value={store}>{children}</AclContext.Provider>;
};

export default AclProvider;

import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import siteconfig from 'src/siteconfig';
import { ConfigContext } from 'src/modules/config';
import { Row, Typography, Button } from 'antd';
import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';

const SITE_CONFIG: any = siteconfig;

function Dashboard() {
  const config = useContext(ConfigContext);

  if (!config.state.appName || SITE_CONFIG[`${config.state.appName}`].menus.length < 1) {
    return (
      <Row style={{ height: '100vh', width: '100vw' }} justify="center" align="middle">
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '40px', fontWeight: 700 }}>You shall not pass!!</div>
          <div style={{ fontSize: '70px', fontWeight: 700 }}>
            <LockOutlined style={{ fontSize: '60px', color: 'orange', marginRight: '20px' }} />
            403
          </div>
          <Typography.Text>
            We are sorry, but you dont have permission to access this page or resource.
          </Typography.Text>
          <div style={{ margin: '30px 0px' }}></div>
          <Button
            icon={<ArrowLeftOutlined />}
            size="large"
            type="primary"
            style={{ marginTop: '30px' }}
            href="/logout"
          >
            Back to Login page
          </Button>
        </div>
      </Row>
    );
  }

  if (SITE_CONFIG[`${config.state.appName}`].pageTitle === 'e-LIVING') {
    let menusArray = SITE_CONFIG[`${config.state.appName}`].menus;
    let arrMenuDefault = menusArray.findIndex(
      (x) => x.key === window.sessionStorage.getItem('firstAclMenu'),
    );
    return arrMenuDefault === -1 ? (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[0].key}`} />
    ) : (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[arrMenuDefault].key}`} />
    );
  } else if (SITE_CONFIG[`${config.state.appName}`].pageTitle === 'WorkFit') {
    let menusArray = SITE_CONFIG[`${config.state.appName}`].menus;
    let tempChild = menusArray.reduce((acc, item) => {
      if (item.children) {
        return [...acc, ...item.children];
      }
      return acc;
    }, []);
    const allmenu: any = [...menusArray, ...tempChild];
    let arrMenuDefault = allmenu.findIndex(
      (x) => x.key === window.sessionStorage.getItem('firstAclMenu'),
    );
    return arrMenuDefault === -1 ? (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[0].key}`} />
    ) : (
      <Redirect to={`/list/${allmenu[arrMenuDefault].key}`} />
    );
  } else if (SITE_CONFIG[`${config.state.appName}`].pageTitle === 'Setia') {
    const web__acl = JSON.parse(localStorage.getItem('cms.web__acl')!);
    const roles = web__acl?.activePlatforms.roles[0];
    const topUpDefaultPage = SITE_CONFIG[`${config.state.appName}`].menus.find(
      (menus) => menus.name === 'Grant Point',
    );
    return roles === 'STAFF_ADMIN_TOPUP' || roles === 'STAFF_CASHIER' ? (
      <Redirect to={`/list/${topUpDefaultPage.key}`} />
    ) : (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[0].key}`} />
    );
  } else if (SITE_CONFIG[`${config.state.appName}`].pageTitle === 'One Invoice') {
    let menusArray = SITE_CONFIG[`${config.state.appName}`].menus;
    const web__acl = JSON.parse(localStorage.getItem('cms.web__acl')!);
    const roles = web__acl?.activePlatforms.roles[0];
    let arrMenuDefault = menusArray.findIndex(
      (x) => x.key === window.sessionStorage.getItem('firstAclMenu'),
    );

    if (roles === 'MERCHANT') {
      return <Redirect to={`/list/manage-component`} />;
    }

    return arrMenuDefault === -1 ? (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[0].key}`} />
    ) : (
      <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[arrMenuDefault].key}`} />
    );
  } else {
    return <Redirect to={`/list/${SITE_CONFIG[`${config.state.appName}`].menus[0].key}`} />;
  }
}

export default Dashboard;

import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

function StaticLayout({ children }) {
  return (
    <Layout>
      <Content className="content-wrapper">
        <div>{children}</div>
      </Content>
    </Layout>
  );
}

export default StaticLayout;

import React, { useEffect, createContext } from 'react';
import useAction from './useAction';
import { IState, IAction, IRenderView, ILoading, IUserType } from './types.d';
import { getFromLocalStorage, saveToLocalStorage } from '../helpers';

interface IProvider {
  state: IState;
  action: IAction;
}

export const IAuthTypes = { IRenderView, ILoading };

const initialState = getFromLocalStorage('authentication') || {
  loading: null,
  profile: null,
  renderView: IRenderView.ENTER_PHONE_NUMBER,
  role: IUserType.SUPER_ADMIN,
  isAuth: false,
};

export const AuthenticationContext = createContext<IProvider>({
  state: initialState,
  action: {
    login: () => {},
    logout: () => {},
    validatePhoneNumber: () => {},
    switchRenderView: () => {},
    validateOtp: () => {},
    forgotPassword: () => {},
    sendOtp: () => {},
    setIsAuth: () => {},
    updateProfile: () => {},
    updatePhone: () => {},
    setEmailValue: () => {},
  },
});

const AuthenticationProvider: React.SFC<{ children: React.ReactNode }> = ({ children }) => {
  const store = useAction(initialState);
  useEffect(() => {
    if (store.state.isAuth) saveToLocalStorage('authentication', store.state);
  }, [store.state]);
  return <AuthenticationContext.Provider value={store}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationProvider;

export interface IState {
  formData: any;
  supportingData: [];
  backTo: string;
  formTitle: string | '';
  formList: [];
  loading: boolean;
}

export enum ITypeInput {
  TEXT = 'text',
  SLUG = 'SLUG',
  NUMBER = 'number',
  CURRENCY = 'CURRENCY',
  PASSWORD = 'password',
  SWITCH = 'switch',
  SWITCH_NUMBER = 'switch',
  SELECT = 'select',
  BUTTON = 'button',
  DYNAMIC_FORM = 'dynamic_form',
  TEXTAREA = 'textarea',
  DATE_PICKER = 'date_picker',
  IMG = 'img',
  PDF = 'pdf',
  OTP = 'otp',
  MODAL_SEARCH = 'modal_search',
}

export interface IProps {
  children: React.ReactNode;
}
export interface IProvider {
  state: IState;
  action: object;
}

export interface IContext {
  state: IState;
}
